@import "globalColor";

.App {
  text-align: center;
  width: 100%;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: $appHeaderBG;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625rem + 2vmin);
  color: $textColorDark;
}

.App-link {
  color: $appLink;
}

.subTitle {
  color: $subTitle;
}
