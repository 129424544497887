@import "../../globalColor";

.footer-text {
  text-align: center;
  color: $subTitle !important;
}

.dark-mode {
  color: $textColorDark !important;
}

.footer-div {
  margin-top: 2rem;
  padding-bottom: 1rem;
}
